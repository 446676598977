import { CognitoUserPool } from 'amazon-cognito-identity-js';

const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
};

const domain = process.env.REACT_APP_COGNITO_DOMAIN;
const redirectUri = process.env.NODE_ENV === 'development' 
  ? "http://localhost:3000"
  : "https://certificates.ipmfilters.com/";

export const userPool = new CognitoUserPool(poolData);

export const cognitoConfig = {
  authority: `https://${domain}`,
  client_id: poolData.ClientId,
  redirect_uri: redirectUri,
  post_logout_redirect_uri: redirectUri,
  response_type: "code",
  scope: "email openid phone",
  loadUserInfo: true,
  metadata: {
    issuer: `https://${domain}`,
    authorization_endpoint: `https://${domain}/oauth2/authorize`,
    token_endpoint: `https://${domain}/oauth2/token`,
    end_session_endpoint: `https://${domain}/logout?client_id=${poolData.ClientId}&logout_uri=${encodeURIComponent(redirectUri)}`,
    userinfo_endpoint: `https://${domain}/oauth2/userInfo`,
    jwks_uri: `https://${domain}/.well-known/jwks.json`
  },
  extraQueryParams: {
    logout_uri: redirectUri
  }
}; 